import './App.css'

import { PageLayout } from 'gecko-ui'
import { Auth0ClientId } from './api/helpers'
import LoggedInContent from './pages/LoggedInContent'
import { asset_manager_theme } from './assets/themes'
import { BasePath, Environment } from './api/defines'

import { GeckoThemeProvider, GeckoTheme, ThemeType, AuthProvider } from 'gecko-ui'
import { ConfigProvider } from 'antd'

// import { pdfjs } from 'react-pdf'
import { SiteContextProvider } from './contextProviders/siteContextProvider'

// // This loads the PDF worker for pdfjs
// // See https://www.npmjs.com/package/react-pdf#import-worker-recommended
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url
// ).toString()

const App = () => {
  return (
    <GeckoThemeProvider theme={GeckoTheme as ThemeType}>
      <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: asset_manager_theme.background.high_vis,
            borderRadius: 6,
            colorBgBase: asset_manager_theme.background.background_color8,
            colorText: asset_manager_theme.text.c1,
            fontFamily: asset_manager_theme.text.font_family2,
            colorTextDisabled: asset_manager_theme.text.c3,
            colorBgContainerDisabled: asset_manager_theme.background.background_color9,
            colorBorder: 'white',
            colorLink: 'white',
            colorIcon: 'white',
            colorPrimaryHover: 'white',
            // Following three used for filter dropdown
            controlItemBgActive: asset_manager_theme.background.background_color8,
            controlItemBgActiveHover: asset_manager_theme.background.background_color8,
            controlItemBgHover: asset_manager_theme.background.background_color7,
            // Alias Token
            //colorBgContainer: 'black',
          },
          components: {
            InputNumber: {
              handleBg: 'transparent',
              handleActiveBg: asset_manager_theme.colors.yellow_dark,
              handleBorderColor: asset_manager_theme.background.background_color5,
              handleHoverColor: asset_manager_theme.background.high_vis,
              hoverBorderColor: asset_manager_theme.background.background_color2,
              activeBorderColor: asset_manager_theme.background.background_color3,
              borderRadius: 4,
              activeShadow: '0 0 0 2px rgba(255, 255, 255, .1)',
              colorBorder: asset_manager_theme.background.background_color5,
            },
            Menu: {
              colorText: asset_manager_theme.text.c4,
              itemSelectedColor: asset_manager_theme.colors.high_vis,
              itemSelectedBg: asset_manager_theme.background.background_color7,
              itemHoverBg: asset_manager_theme.background.background_color7,
              itemDisabledColor: asset_manager_theme.text.c3,
              itemActiveBg: asset_manager_theme.background.background_color7,
              itemHeight: 42,
            },
            Spin: {
              colorPrimary: asset_manager_theme.colors.high_vis,
            },
            Table: {
              headerBg: asset_manager_theme.background.background_color10,
              colorBgContainer: asset_manager_theme.background.background_color9,
              rowHoverBg: asset_manager_theme.background.background_color10,
              rowExpandedBg: asset_manager_theme.background.background_color7,
              filterDropdownBg: asset_manager_theme.background.background_color8,
              headerFilterHoverBg: asset_manager_theme.colors.high_vis,
            },
            Tag: {
              colorPrimary: asset_manager_theme.background.high_vis,
              colorPrimaryHover: asset_manager_theme.background.background_color1,
              colorTextLightSolid: 'black',
              colorPrimaryActive: asset_manager_theme.background.background_color3,
              colorText: 'white',
              colorInfoBg: asset_manager_theme.background.background_color1,
            },
            Checkbox: {
              colorPrimary: asset_manager_theme.background.high_vis,
              colorPrimaryHover: asset_manager_theme.background.background_color1,
              colorWhite: 'black',
            },
            Button: {
              //Default button
              defaultBg: 'transparent',
              defaultColor: asset_manager_theme.colors.high_vis,
              defaultBorderColor: asset_manager_theme.colors.high_vis,
              primaryColor: asset_manager_theme.text.c2,
              primaryShadow: asset_manager_theme.background.background_color8,
              colorPrimaryHover: '#A1AD00',
              colorText: asset_manager_theme.colors.high_vis,
              defaultGhostColor: asset_manager_theme.background.background_color2,
              defaultGhostBorderColor: asset_manager_theme.background.background_color5,
            },
            DatePicker: {
              colorPrimaryText: 'white',
              colorIcon: asset_manager_theme.background.background_color4,
              colorIconHover: asset_manager_theme.colors.high_vis,
              activeShadow: asset_manager_theme.background.background_color7,
              activeBorderColor: asset_manager_theme.background.background_color5,
              colorTextPlaceholder: asset_manager_theme.background.background_color4,
              colorBorder: asset_manager_theme.background.background_color5,
              colorSplit: asset_manager_theme.background.background_color6,
              colorTextLightSolid: asset_manager_theme.background.background_color8,
              cellHoverWithRangeBg: asset_manager_theme.background.background_color5,
              cellActiveWithRangeBg: asset_manager_theme.background.background_color6,
            },
            Tabs: {
              colorBorderSecondary: asset_manager_theme.background.background_color8_5,
            },
            Select: {
              colorTextPlaceholder: asset_manager_theme.background.background_color4,
              optionSelectedBg: asset_manager_theme.colors.high_vis,
              optionSelectedColor: 'black',
              colorBorder: asset_manager_theme.background.background_color5,
              clearBg: 'white',
              colorPrimary: asset_manager_theme.background.background_color5,
            },
            Modal: {
              contentBg: asset_manager_theme.background.background_color8,
            },
            Input: {
              borderRadius: 4,
              activeShadow: '0 0 0 2px rgba(255, 255, 255, .1)',
              hoverBorderColor: asset_manager_theme.background.background_color2,
              activeBorderColor: asset_manager_theme.background.background_color3,
            },
            Slider: {
              railSize: 6,
              railBg: asset_manager_theme.background.background_color5,
              railHoverBg: asset_manager_theme.background.background_color4,
              trackBg: asset_manager_theme.colors.yellow_light,
            },
            Tree: {
              colorPrimary: asset_manager_theme.background.background_color6,
              titleHeight: 36,
            },
          },
        }}>
        <AuthProvider
          clientId={Auth0ClientId()}
          environment={Environment()}
          basePath={BasePath()}>
          <PageLayout>
            <LoggedInContent />
          </PageLayout>
        </AuthProvider>
      </ConfigProvider>
    </GeckoThemeProvider>
  )
}

export default App
