import React, { useState } from 'react'

import { HoveredCard } from '../../../components/Common/HoverCard'
import { DownloadIcon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { asset_manager_theme } from '../../../assets/themes'
import { InspectionReportDetail } from '../../../api/useFDEBackend'
import { useGetSignedUrl } from '../../../util/RolodexInterface'
import { useAuthInfo } from '../../../contextProviders/useAuthInfo'
import pdf_icon from '../../../assets/pdf_icon.png'
import word_icon from '../../../assets/word_icon.png'
import styled from 'styled-components'
// import { RolodexPdfViewerModal } from '../../../components/PdfViewer/RolodexPdfViewerModal'

const ReportCard = styled.div`
  display: flex;
  gap: 1rem;
`

const fileIconStyle = {
  margin: 'auto',
  width: '15px',
  marginTop: '5px',
}

const ActionIcons = styled.div<{ $disabled?: boolean }>`
  margin-left: auto;
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  display: flex;
  gap: 7px;
`

const StyledDownloadIcon = styled(DownloadIcon)<{ $disabled?: boolean }>`
  color: white;
  transition-property: color;
  transition-duration: 0.3s;

  &:hover {
    outline: none;
    color: ${asset_manager_theme.background.high_vis};
  }
`

const ReportName = styled.p`
  font-family: Manrope, sans-serif;
  color: ${asset_manager_theme.text.c1};
  font-weight: bold;
`

export const InspectionReportCard: React.FC<{ report: InspectionReportDetail }> = ({
  report,
}) => {
  const auth0 = useAuthInfo()
  const signedSwrRes = useGetSignedUrl(report.entry_id, report.path, auth0)

  const [pdfViewOpen, setPdfViewOpen] = useState<boolean>(false)

  return (
    <div>
      <HoveredCard
        alignment='side'
        disabled={signedSwrRes.error || signedSwrRes.isLoading}>
        <ReportCard>
          <div
            style={{ display: 'flex', gap: '1rem' }}
            onClick={() => setPdfViewOpen(!pdfViewOpen)}>
            {report.file_type === 'pdf' && <img style={fileIconStyle} src={pdf_icon} />}
            {report.file_type === 'docx' && <img style={fileIconStyle} src={word_icon} />}
            <ReportName>{report.visible_name}</ReportName>
          </div>
          <ActionIcons>
            <Link to={signedSwrRes?.data} target={'_blank'}>
              <StyledDownloadIcon strokeWidth={5} />
            </Link>
          </ActionIcons>
          {/* {report.file_type === 'pdf' && (
            <RolodexPdfViewerModal
              rolodexFile={report}
              isOpen={pdfViewOpen}
              setIsOpen={setPdfViewOpen}
            />
          )} */}
        </ReportCard>
      </HoveredCard>
    </div>
  )
}
